<template>
  <page title="满意度评价">
    <commonbanner></commonbanner>
    <div class="mt-3 overflow-hidden">
    <div class="container">
      <div class="allBorder color-white mt-3 pt-5">
        <a-row :gutter="[16,16]">
          <a-col :span="11" :offset="1">
            <h5 style="display: inline"> XX省 </h5>
            <a-select class="region">
              <a-option>aa</a-option>
            </a-select>
          </a-col>
          <a-col :span="12">
            <h5 style="display: inline"> 时间统计 </h5>
            <a-range-picker />
          </a-col>
        </a-row>
        <a-row type="flex" justify="start" align="center" :gutter="[128,16]" class="pt-4">
          <a-col :span="4">
            <a-icon type="ordered-list" class="theme"/> 评价数
            <p><span class="first theme" >12212件</span></p>
          </a-col>
          <a-col :span="4">
            <a-icon type="carry-out" class="theme"/> 办件数
            <p><span class="first theme" >129212件</span></p>
          </a-col>
          <a-col :span="4">
            <a-icon type="fall" class="theme"/> 差评数
            <p><span class="first theme" >229212个</span></p>
          </a-col>
          <a-col :span="4">
            <a-icon type="pie-chart" class="theme"/>点赞数
            <p><span class="first theme" >929212个</span></p>
          </a-col>
          <a-col :span="4">
            <a-icon type="rise" class="theme"/> 好评率
            <p><span class="first theme" >96.60</span>%</p>
          </a-col>
        </a-row>
      </div>

      <div class="mt-4 ranking">
        <a-row justify="start" align="top" :gutter="[16,16]">
          <a-col :span="12">
            <div
              v-infinite-scroll="handleInfiniteOnLoad"
              class="demo-infinite-container"
              :infinite-scroll-disabled="busy"
              :infinite-scroll-distance="10"
            >
            <a-card title="好评排行榜" :bordered="false">
                <a-list :data-source="rankUnified" :split="false">
                  <a-list-item slot="renderItem" slot-scope="item">
                    <a-list-item-meta >
                      <div slot="description">
                        <p>办件数: <i>{{ item.count }}</i>评价数:<i> {{ item.commit }} </i>点赞数:<i> {{ item.dirCommit }} </i>满意率:<i> {{item.satisfied}}</i></p>
                      </div>
                      <a slot="title" href="https://www.antdv.com/">{{ item.title }}</a>
                      <a-badge slot="avatar" color="#c8e2f8"/>
                    </a-list-item-meta>
                  </a-list-item>
                </a-list>
            </a-card>
            </div>
          </a-col>
          <a-col :span="12">
            <div
              v-infinite-scroll="handleInfiniteOnLoad"
              class="demo-infinite-container"
              :infinite-scroll-disabled="busy"
              :infinite-scroll-distance="10"
            >
              <a-card title="差评排行榜" :bordered="false">
                <a-list :data-source="rankUnified" :split="false">
                  <a-list-item slot="renderItem" slot-scope="item">
                    <a-list-item-meta >
                      <div slot="description">
                        <p>办件数: <i>{{ item.count }}</i>评价数:<i> {{ item.commit }} </i>点赞数:<i> {{ item.dirCommit }} </i>满意率:<i> {{item.satisfied}}</i></p>
                      </div>
                      <a slot="title" href="https://www.antdv.com/">{{ item.title }}</a>
                      <a-badge slot="avatar" color="#c8e2f8"/>
                    </a-list-item-meta>
                  </a-list-item>
                </a-list>
              </a-card>
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="allBorder color-white mt-3">
        <h5 class="pt-3 pl-3 ant-card-head">评价渠道</h5>
        <a-row :gutter="[16,16]">
          <a-col :span="8" class="p-3">
            <div id="chart" ref="chart" :style="{width: '500px', height: '300px'}">
            </div>
          </a-col>
          <a-col :span="15" :offset="1" class="pt-5">
            <div class="useInlineBlock">
              <div class="evaluation_icon"><a-icon type="desktop" /></div>
              <div class="evaluation_data"><h5>法律服务网</h5><p>12341</p></div>
            </div>
            <div class="useInlineBlock">
              <div class="evaluation_icon"><a-icon type="qrcode" /></div>
              <div class="evaluation_idata"><h5>小程序</h5><p>12341</p></div>
            </div>
            <div class="useInlineBlock">
              <div class="evaluation_icon"><a-icon type="apartment" /></div>
              <div class="evaluation_data"><h5>实体平台</h5><p>12341</p></div>
            </div>
            <div class="useInlineBlock">
              <div class="evaluation_icon"><a-icon type="control" /></div>
              <div class="evaluation_data"><h5>终端</h5><p>12341</p></div>
            </div>
            <div class="useInlineBlock">
              <div class="evaluation_icon"><a-icon type="smile" /></div>
              <div class="evaluation_data"><h5>服务热线</h5><p>12341</p></div>
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="Progress mt-4">
        <a-row>
          <a-col :span="12" :offset="0" class="float-left pr-2">
            <a-card title="用户热评" :bordered="false" class="allBorder">
              <div v-for="item in hotCommont" :key="item.title">
                <p style="font-size: 15px">{{ item.title }}</p>
                <a-progress
                  :showInfo="false"
                  :stroke-color="{
                  '0%': '#108ee9',
                  '100%': '#87d068',
                 }"
                  :percent="item.percentage"
                />
              </div>
            </a-card>
          </a-col>
          <a-col :span="12" :offset="0" class="float-right pl-2">
            <a-card title="差评最多" :bordered="false" class="allBorder">
              <div v-for="item in difCommont" :key="item.title">
                <p style="font-size: 15px">{{ item.title }}</p>
                <a-progress
                  :showInfo="false"
                  :stroke-color="{
                  '0%': '#f04b4b',
                  '100%': '#fde7c6',
                 }"
                  :percent="item.percentage"
                />
              </div>
            </a-card>
          </a-col>
        </a-row>
      </div>
      <div class="message">
        <a-card title="大家都在评" :bordered="false" class="allBorder mt-4">
          <a-list item-layout="horizontal" :data-source="rankUnified">
            <a-list-item slot="renderItem" slot-scope="item">
              <a-list-item-meta
              >
                <a slot="title" href="https://www.antdv.com/">{{ item.title }}</a>
                <a-avatar
                  slot="avatar"
                  src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                />
                <div slot="description">
                  <p>办理事项: 公路超限运输许可</p><p style="float: right">2020-12-18 11:40:38</p>
                  <p><a-rate :default-value="2" disabled /><span> 办理部门:{{ item.title }}</span><span> 评价来源: {{ item.count }}</span></p>
                  <p><a-tag color="cyan">
                    {{ item.commit }}
                  </a-tag></p>
                </div>
              </a-list-item-meta>
            </a-list-item>
          </a-list>
          <a-pagination :default-current="6" :total="500" />
        </a-card>
      </div>
    </div>
    </div>
  </page>
</template>
<script>
import infiniteScroll from 'vue-infinite-scroll'
import Commonbanner from '@/components/base/Commonbanner'
export default {
  directives: { infiniteScroll },
  name: 'DetailsEvaluation',
  components: {
    Commonbanner
  },
  data () {
    return {
      rankUnified: [
        {
          key: '1',
          title: 'XX省建筑科学研究院司法鉴定所',
          count: '53123',
          commit: '23415',
          difCommit: '0',
          satisfied: '100%'
        },
        {
          key: '2',
          title: 'XX五洲司法鉴定所',
          count: '35609',
          commit: '23413',
          difCommit: '0',
          satisfied: '100%'
        },
        {
          key: '3',
          title: 'XX铜城司法医学鉴定所',
          count: '293192',
          commit: '123441',
          difCommit: '0',
          satisfied: '100%'
        },
        {
          key: '1',
          title: '会宁县人民医院司法鉴定所',
          count: '53123',
          commit: '23415',
          difCommit: '0',
          satisfied: '100%'
        },
        {
          key: '2',
          title: 'XX中信司法鉴定所',
          count: '35609',
          commit: '23413',
          difCommit: '0',
          satisfied: '100%'
        },
        {
          key: '3',
          title: 'XX金麟司法医学鉴定所',
          count: '293192',
          commit: '123441',
          difCommit: '0',
          satisfied: '100%'
        }
      ],
      loading: false,
      busy: false,
      hotCommont: [
        {
          title: '服务热情效率高',
          percentage: 99.8
        },
        {
          title: '一窗受理一次办结',
          percentage: 70.8
        },
        {
          title: '可以全程网上办理',
          percentage: 30.8
        },
        {
          title: '可以就近办理',
          percentage: 24.8
        },
        {
          title: '可以先受理后补材料',
          percentage: 17.8
        },
        {
          title: '不用提交证明',
          percentage: 10.8
        }
      ],
      difCommont: [
        {
          title: '服务态度差效率低',
          percentage: 99.8
        },
        {
          title: '在办事指南之外增加新的审批程序',
          percentage: 85.8
        },
        {
          title: '多头跑窗口和部门',
          percentage: 65.8
        },
        {
          title: '无理由超过法定办理时间',
          percentage: 50.8
        },
        {
          title: '需提供办事指南之外的申报材料',
          percentage: 45.8
        },
        {
          title: '办理完该事项需要到窗口2次以上',
          percentage: 40.8
        }
      ],
      option: {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
          orient: 'vertical',
          left: 10,
          data: ['法律服务网', '小程序', '实体平台', '终端', '服务热线']
        },
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '18',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [

              { value: 310, name: '法律服务网', itemStyle: { color: '#38a2fc' } },
              { value: 234, name: '小程序', itemStyle: { color: '#4ecb7a' } },
              { value: 48, name: '实体平台', itemStyle: { color: '#985fe2' } },
              { value: 148, name: '终端', itemStyle: { color: '#fad338' } },
              { value: 648, name: '服务热线', itemStyle: { color: '#35c9ca' } }
            ]
          }
        ]
      }
    }
  },
  beforeMount () {
    this.fetchData(res => {
      this.rankUnified = res.data.result.songs
    })
  },
  mounted () {
    this.initCharts()
  },
  methods: {
    initCharts () {
      const myChart = this.$echarts.init(this.$refs.chart)
      console.log(this.$refs.chart)
      // 绘制图表
      myChart.setOption(this.option)
    },
    fetchData (callback) {
      // this.$api.axios.get('http://musicapi.leanapp.cn/search?keywords=%E8%96%9B%E4%B9%8B%E8%B0%A6').then((res) => {
      //   callback(res)
      // })
    },
    handleInfiniteOnLoad () {
      const data = this.rankUnified
      this.loading = true
      if (data.length > 14) {
        this.$message.warning('Infinite List loaded all')
        this.busy = true
        this.loading = false
        return
      }
      this.fetchData(res => {
        this.rankUnified = data.concat(res.results)
        this.loading = false
      })
    }
  }
}
</script>
<style scoped src="../../assets/css/detailsEvaluation.css"></style>
